import { Modal } from "react-bootstrap";
import { useWhiteLabelLan } from "@hooks/useLayout";
import {
  ModalTitle,
  ModalHeader,
  ModalClose,
  ModalHeaderContainer,
  ModalButtonClose,
  ModalBody,
  CustomModalFooter,
  ColAddRoom,
  ColBtnClose,
} from "./styles";
import { GuestConfigurationBody } from "@components/GuestConfigurationOverlay/Body";
import { useGlobalContext } from "@hooks/useGlobalContext";
import { AddRoomButton } from "@components/GuestConfigurationOverlay/Body/AddRoom";

type GestConfigurationModalPropsType = {
  isMobile: boolean;
  target: any;
  show: boolean;
  setShow: any;
  removeGuestConfiguration: (id: number) => void;
  setPersonTotal: (config: any, type: string) => void;
};

export const GuestConfigurationModal = ({
  isMobile,
  show,
  setShow,
  removeGuestConfiguration,
  setPersonTotal,
}: GestConfigurationModalPropsType) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { search, setSearch } = useGlobalContext();
  const handleUpdateGuestValue = (
    id: number,
    type: "adult" | "children" | "room",
    process: string
  ) => {
    const guestConfig = search.paxConfig;
    const updatedData = guestConfig.map((data, index) => {
      if (index == id) {
        switch (type) {
          case "adult":
            data.adult =
              process == "plus"
                ? data.adult + 1
                : data.adult == 1
                ? 1
                : data.adult - 1;
            break;
          case "children":
            data.children =
              process == "plus"
                ? data.children >= 4
                  ? 4
                  : data.children + 1
                : data.children <= 0
                ? 0
                : data.children - 1;
            if (process == "plus") {
              data.child.push({ age: "1" });
            } else {
              data.child.pop();
            }
            break;
          case "room":
            data.room =
              process == "plus"
                ? data.room + 1
                : data.room >= 1
                ? 1
                : data.room - 1;
            break;
        }
      }
      return data;
    });
    setSearch({ ...search, paxConfig: updatedData });
    setPersonTotal(search.paxConfig, "adult");
    setPersonTotal(search.paxConfig, "children");
  };

  return (
    <>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <ModalHeader>
          <ModalHeaderContainer fluid>
            <ModalTitle className="guestconfigurationtitle-hotel">
              Room Details
            </ModalTitle>
            <ModalClose
              src="/images/general/mainClose.svg"
              onClick={() => setShow(false)}
            ></ModalClose>
          </ModalHeaderContainer>
        </ModalHeader>
        <ModalBody>
          <GuestConfigurationBody
            isMobile={isMobile}
            setShow={setShow}
            removeGuestConfiguration={removeGuestConfiguration}
            updateGuestValue={handleUpdateGuestValue}
            setPersonTotal={setPersonTotal}
          />
        </ModalBody>
        <CustomModalFooter>
          <ColAddRoom xs={4} md={0}>
            <AddRoomButton
              isMobile={isMobile}
              setPersonTotal={setPersonTotal}
            />
          </ColAddRoom>
          <ColBtnClose xs={6} md={12}>
            <ModalButtonClose
              className="btncloseconfigurationpax-hotel"
              onClick={() => setShow(false)}
            >
              {getLangLabel("CLOSE")}
            </ModalButtonClose>
          </ColBtnClose>
        </CustomModalFooter>
      </Modal>
    </>
  );
};
