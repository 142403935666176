import {
  DropdownMain,
  DropdownMainItem,
  DropdownMainMenu,
  DropdownMainToggle,
  DDContentText,
  DDText,
  ArrowDownSpan,
  HotelIcon,
  TextIconArrowDown,
  Discount,
  PerNight,
} from "./styles";
import parse from "html-react-parser";
import { Col, Row, Spinner } from "react-bootstrap";
import { useMain } from "@contexts/mainContext";
import { useHotel } from "@contexts/hotelContext";
import { getCookie } from "cookies-next";
import { getCurrencySimbol } from "@helpers/helperHotelRoom";
import { useWhiteLabelLan } from "@hooks/useLayout";

type OptionsType = {
  value: any;
  label: string;
  price?: PriceType;
  no_of_nights?: number;
};
type DropDownMenuProps = {
  title: string;
  icon?: string;
  value: OptionsType;
  options: OptionsType[];
  loading: boolean;
  onChange: (optionSelected: OptionsType) => void;
  isMobile: boolean;
};

export const DropDownMenu: React.FC<DropDownMenuProps> = ({
  title,
  options,
  value,
  onChange,
  icon,
  loading,
  isMobile,
}) => {
  const { withTax } = useMain();
  const { currentHotelMinPrice } = useHotel();
  const { getLangLabel } = useWhiteLabelLan();

  const getDiffPrice = (noOfNights: number, price?: PriceType) => {
    const hotelPrice = Number(withTax ? price?.inclusive : price?.exclusive);
    const hotelPricePerNight = hotelPrice / noOfNights;
    const diff =
      currentHotelMinPrice > 0 && hotelPrice > 0
        ? currentHotelMinPrice - hotelPrice
        : 0;
    const symb = getCurrencySimbol(String(getCookie("currency_code") || "USD"));
    if (diff == 0) {
      return "";
    } else if (diff > 0) {
      const per = (100 * hotelPrice) / currentHotelMinPrice;
      return <Discount> -{per.toFixed(0)}%</Discount>;
    } else if (diff < 0) {
      return (
        <PerNight>
          {symb}
          {Math.abs(hotelPricePerNight).toFixed(2)} {getLangLabel("per Night")}
        </PerNight>
      );
    }
    return "";
  };
  return (
    <DropdownMain theme={{ isMobilePage: isMobile, icon }}>
      <DropdownMainToggle
        variant="light"
        id="dropdown-basic"
        theme={{
          isMobilePage: isMobile,
        }}
      >
        {" "}
        <DDContentText>
          <HotelIcon
            className="imgDestination"
            theme={{
              isMobilePage: isMobile,
              icon,
            }}
          />{" "}
          <DDText
            theme={{
              isMobilePage: isMobile,
            }}
          >
            {parse(String(title).replace(/"/g, ""))}
          </DDText>
          <ArrowDownSpan>
            <TextIconArrowDown
              theme={{
                icon: "/images/filter/pexarrowdown.svg",
                isMobilePage: isMobile,
              }}
            />
          </ArrowDownSpan>
        </DDContentText>
      </DropdownMainToggle>

      <DropdownMainMenu
        theme={{
          isMobilePage: isMobile,
        }}
      >
        {options.map((opt, index) => {
          return (
            <DropdownMainItem
              key={`opt-drop-menu-${index}`}
              active={value?.value == opt.value}
              onClick={() => onChange(opt)}
            >
              {opt.label} {getDiffPrice(opt.no_of_nights || 1, opt?.price)}
            </DropdownMainItem>
          );
        })}
        {loading && (
          <Row className="m-2">
            <Col>
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="primary" />
              </div>
            </Col>
          </Row>
        )}
      </DropdownMainMenu>
    </DropdownMain>
  );
};
