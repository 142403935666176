import { Col, Container, Overlay, Popover, Row, Modal } from "react-bootstrap";
import styled from "styled-components";

export const MainDiv = styled(Container)`
  padding-top: 0.1rem;
  padding-bottom: 0.5rem;
  max-height: ${({ theme }: any) =>
    theme.isMobile == true ? "auto" : "44rem"};
  //width: 33rem;
  width: 100%;
  overflow-y: auto;
  padding: 0;
`;
export const Div = styled(Container)`
  padding: 0;
  margin: 0;
  padding-left: 2rem;
  background: ${(props) =>
      props.theme.url
        ? `url(${props.theme.url})`
        : `url("/images/autocomplete-icon/HotelInvoice.svg")`}
    no-repeat;
  background-size: 17px;
  background-position: left center;
  overflow: hidden;
  width: 100%;
  border-radius: 30px;
  :hover {
    background-color: #f1f2f7;
  }

  @media only screen and (min-width: 992px) {
    background-position: left 1rem center;
  }
`;
export const OverlayCustom = styled(Overlay)``;

export const RowDiv = styled(Row)`
  padding: 10px;
  padding-left: 35px;
  @media only screen and (max-width: 992px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`;
export const Title = styled(Row)`
  color: #6c6ba2;
  font-weight: bold;
  font-size: 15px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
  }
`;

export const Destination = styled(Row)`
  color: #454d61;
  font-weight: 500;
  font-size: 15px;
  @media only screen and (max-width: 992px) {
    font-size: 14px;
  }
`;
export const Type = styled(Col)`
  display: table-cell;
  color: #454d61;
  font-weight: bold;
  /* vertical-align: middle; */
  align-items: center;
  /* justify-content: center; */
  text-align: center;
`;

export const PopoverCustom = styled(Popover)`
  /* min-width: ${({ theme }: any) => (theme.isCookie ? "23rem" : "35rem")}; */
  //max-width: 50rem;
  border-radius: 10px;
  width: 30rem;
  & > .popover-arrow {
    display: none;
  }
  -webkit-box-shadow: 0px 0px 5px 2px rgba(69, 77, 97, 0.45);
  box-shadow: 0px 0px 5px 2px rgba(69, 77, 97, 0.45);
  max-width: 35rem;

  @media only screen and (max-width: 992px) {
    border-radius: 0px;
    width: 32rem;
    max-width: 108%;
    margin-left: -1.2rem;
    height: 60rem;
    margin-top: -0.2rem;
    height: 49rem;
  }
`;
export const Span = styled.span`
  width: 100%;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  @media only screen and (max-width: 992px) {
    font-size: small;
  }
`;

export const MainDivSuggestedNotFound = styled(Container)`
  height: 13rem;
  overflow: hidden;
`;
export const LoaderDiv = styled(Container)`
  height: 13rem;
  width: 20rem;
  overflow: hidden;
  text-align: center;
  @media only screen and (max-width: 992px) {
    width: 18rem;
  }
`;
export const DivTittleSuggestedNotFound = styled.div`
  font-size: 22px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #686ea3;
  margin: 15px 0;
  text-transform: capitalize;
`;

export const DivImgSuggestedNotFOund = styled.div`
  background: url("/images/general/no-result-autocomplete.jpg");
  background-repeat: no-repeat;
  background-size: 13rem;
  margin-left: 1.4rem;
  width: 13rem;
  height: 12rem;

  //border: 2px solid;
`;

export const CustomPopoverHeader = styled(Popover.Header)`
  background-color: #f4f5f9;
  padding: 7px 33px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #686ea3;
  cursor: pointer;
  z-index: 0;
  /* text-transform: capitalize; */

  margin-top: ${({ theme }: any) => (theme.isMobile == true ? "0rem" : "0rem")};
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    width: 100%;
    padding: 7px 3.4rem;
  }
`;

export const RowDivCookie = styled(Row)`
  padding: 0.5rem;
  padding-left: 2rem;
`;
export const DivCookie = styled(Container)`
  background: ${(props) =>
      props.theme.url
        ? `url(${props.theme.url})`
        : `url("/images/autocomplete-icon/HotelInvoice.svg")`}
    no-repeat;
  background-size: 13px;
  background-position-y: 0.7rem;
  background-position-x: 0.7rem;

  width: 100%;
  border-radius: 30px;
  :hover {
    background-color: #f1f2f7;
  }
`;

export const SpanDateCookie = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #adadad;
  margin-top: 7px;
  margin-left: -0.7rem;
  display: block;
`;

export const SpanPersonsCookie = styled.span`
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #adadad;
  margin-top: 7px;
  margin-left: -0.7rem;
  display: block;
`;

export const RowModalSeach = styled(Row)`
  box-sizing: border-box;
  box-shadow: 0 1px 9px #ccc;

  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    width: 100%;
    margin: 0;
  }
`;

export const ModalHeaderContainer = styled(Container)`
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    width: 100%;
    margin-left: 0rem;
  }
`;
export const ModalClose = styled.img`
  width: 0.9rem;
  height: 0.9rem;
  margin-top: 1rem;
  margin-left: -1.5rem;
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    margin-left: 0rem;
  }
`;
export const ModalBody = styled(Modal.Body)``;

export const InputSearchModal = styled.input`
  width: 100%;
  height: 45px;
  font-size: 16px;
  font-weight: 700;
  padding-left: 40px;
  padding-right: 3.5em;
  border: none;
  outline: none;
  color: #676ea4;
  ::placeholder {
    color: #676ea4;
  }
`;
