import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const ModalCustom = styled(Modal)`
  background: rgba(64, 67, 120, 0.75);
  & .modal-content {
    border-radius: ${({ theme }: any) => (theme.isMobile ? "0px" : "35px")};
    min-height: 15rem;
    padding: ${({ theme }: any) =>
      theme.isMobile ? "30px 70px" : "30px 70px"};
  }
  & .modal-dialog {
    max-width: ${({ theme }: any) => (theme.isMobile ? "" : "35rem")};
  }
`;

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SpanLabel = styled.span`
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #424577;
`;

export const DivBoldSignIn = styled.span`
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #424577;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 2rem;
`;

export const DivImgCloseModal = styled.div``;
export const ImgCloseModal = styled.img`
  content: url(/images/search/CloseBlue.svg);
  width: 1.3rem;
  height: 1.3rem;
`;

export const DivContainerSignIn = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
`;
export const SignInBtn = styled.button`
  background-color: #404378 !important;
  width: 10rem;
  border-radius: 15px;
  padding: 10px 20px;
  border: none;
`;

export const ContainerLabelBtnSignin = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const DivContainerImgUser = styled.div``;
export const ImgUser = styled.div`
  content: url(/images/search/userIconSignIn.png);
  width: 1.4rem;
  height: 1.4rem;
`;
export const LabelSignIn = styled.span`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2px;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  font-weight: 600;
  color: #fff;
`;
