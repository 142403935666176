import { Col } from "react-bootstrap";
import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  background-color: ${({ theme }: any) =>
    theme.isMobile == true ? `#FFFFFF` : `#f1f1f1`};
  border: none;
  color: #4d4d4d;
  font-weight: 400;
  outline: 0;
  margin-top: ${({ theme }: any) =>
    theme.isMobile == true ? `0rem` : `0.8rem`};
  font-size: 15px;
  margin-left: ${({ theme }: any) => (theme.isMobile == true ? `0.35rem` : ``)};
  text-transform: capitalize;

  @media only screen and (min-width: 1025px) and (max-width: 1350px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1250px) {
    font-size: 11px;
  }
  @media only screen and (min-width: 1025px) and (max-width: 1100px) {
    font-size: 10px;
  }
`;
export const ColMain = styled(Col)`
  //font-weight: 600;
  //cursor: pointer;
  //text-align: center;
  //border: none;
  //border-radius: 2rem;
  //box-shadow: 0 0 10px 0 #ccc;
  margin: 0;
  padding: 0;
  text-align: center;
  
  @media only screen and (min-width: 992px) {
    max-width: 13rem;
  }
`;
export const WalletPersonalContainer = styled.div`
  display: ${({ theme }: any) => (theme.show ? "flex" : "none")};
  justify-content: ${({ theme }: any) => (theme.isMobile ? "left" : "right")};
  margin-top: ${({ theme }: any) => (theme.isMobile ? "0rem" : "0.2rem")};
  margin-bottom: ${({ theme }: any) => (theme.isMobile ? "0.8rem" : "")};
  margin-right: 0.5rem;
`;
