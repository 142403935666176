import axios from "axios";
// import { getCookie, checkCookies } from "cookies-next";

export const getHotelAutoSuggest = async (
  val: string,
  latitude?: string | number,
  longitude?: string | number
): Promise<HotelAutoSuggestDataType[]> => {
  try {
    const response = await axios.post(`/api/booking/getAutoSuggest`, {
      val,
      latitude,
      longitude,
    });

    console.info("response ho", response);
    return response?.data?.list;
  } catch (error) {
    console.info(error);
    return [];
  }
};

type AutoSuggestReqType = {
  url: string;
  args: {
    val: string;
    latitude?: string | number;
    longitude?: string | number;
  };
};

export const fetcherAutoSuggest = async (
  params: AutoSuggestReqType
): Promise<HotelAutoSuggestDataType[]> => {
  return axios
    .post(params.url, {
      ...params.args,
    })
    .then((res) => res?.data?.list);
};
