import { create } from "zustand";
interface paymentCardByOrgState {
  paymentCardByOrd: Get_Payment_Card_By_Org_API_Response_Type[] | null;
  setPaymentCardByOrd: (
    paymentCardByOrd: Get_Payment_Card_By_Org_API_Response_Type[] | null
  ) => void;
}
export const usePaymentCardByOrg = create<paymentCardByOrgState>((set) => ({
  paymentCardByOrd: null,
  setPaymentCardByOrd: (
    value: Get_Payment_Card_By_Org_API_Response_Type[] | null
  ) => set({ paymentCardByOrd: value }),
}));
