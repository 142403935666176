import styled from "styled-components";
import { Col, Container, Popover, Row } from "react-bootstrap";

export const MainContainer = styled(Container)`
  transition: width 2s;
`;

export const PopoverCustom = styled(Popover)`
  min-width: 25px;
  max-width: 800px;
  border-radius: 20px;
  background-color: #fff;
  width: 450px; //380px;
  & > .popover-arrow {
    display: none;
  }

  -webkit-box-shadow: 0px 0px 5px 2px rgba(69, 77, 97, 0.45);
  box-shadow: 0px 0px 5px 2px rgba(69, 77, 97, 0.45);
  padding: 0.5rem 2rem 1rem 2rem;
`;
//General Componenets
export const CustomLabel = styled.span`
  color: ${(props) => (props.theme.color ? props.theme.color : "##6b71a6")};
  font-size: 18px;
  font-weight: ${(props) => (props.theme.isMobile ? 600 : "")};
  margin: 2px;
  margin-left: 5px;
  margin-right: 10px;
  text-align: center;
  @media only screen and (max-width: 992px) {
    font-size: 14px;
  }
`;

export const GuestMainLabel = styled.span`
  font-size: 13px;
  color: #4d4d4f;
  font-weight: 500;
`;
export const CustomSpan = styled.span`
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 0.6px;
  color: #4d4d4f;
  margin: 5px 0px 5px 0px;
`;

// header Section
export const TitleRow = styled(Row)`
  padding: 20px 26px 2px 0px;
  text-align: left;
  color: #4d4d4f;
  font-weight: 600;
  @media only screen and (max-width: 992px) {
    padding: 10px 20px 14px 10px;
  }
`;
export const TitleCol = styled(Col)`
  //width: 33.33%;
  font-size: 15px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
  }
`;

//BodySection
export const BodyRow = styled(Row)`
  background-color: ${(props) =>
    props.theme.color ? props.theme.color : "white"};
  margin-bottom: 20px;
  border-top: 1px solid #ccc;
  padding: 14px 0px 14px 0px;
`;
export const BodyChildrenRow = styled(Row)`
  background-color: ${(props) =>
    props.theme.color ? props.theme.color : "white"};
  margin-top: 15px;
  //padding: 20px 15px 10px 15px;
  padding: 20px 0px 18px 10px;
  margin-right: 0rem;
`;

export const BodyCol = styled(Col)`
  display: flex;
  margin-bottom: -20px;
  text-align: center;
  align-items: center;
  padding: 0;
`;

export const BodyChildrenCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: -20px;
  margin-top: -20px;
  text-align: center;
  align-items: center;
  width: 30px;
`;

export const UL = styled.ul`
  list-style-type: none;
  padding-left: 0rem;
`;
export const LI = styled.li`
  width: 80px; //80px;
`;

export const CustomSelect = styled.select`
  background-color: #fff;
  border: 1px solid #1371ba;
  color: #4d4d4f;
  border-spacing: 0;
  border-collapse: initial;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 65px;
  height: 25px;
  margin-right: 8px;
  border-radius: 12px;
  cursor: pointer;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  option {
    margin: 10px;
    display: inline-block;
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
  }
`;

export const CustomSelectOption = styled.option``;
// Footer Section
export const FooterRow = styled(Row)`
  display: ${({ theme }: any) => (theme.show ? "" : "none")};
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding: 8px 0px 2px 0px;
  width: 100%;
`;
export const FooterCol = styled(Col)`
  width: 40%;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
export const CloseCol = styled(Col)`
  display: ${(props) => (props.theme.show ? "block" : "none")};
  margin-left: 4rem;
  width: 60%;
`;
export const FooterCol2 = styled(Col)``;
export const CloseCol2 = styled(Col)`
  display: ${(props) => (props.theme.show ? "block" : "none")};
  //margin-left: 4rem;
`;
/*
export const AddRoomButton = styled(Button)`
  background-color: transparent;
  border-color: transparent;
  color: #4d4d4f;
  width: max-content;
  font-size: 14px;
  padding: 0;
  margin-left: -12px;
  display: flex;
  align-items: center;
  :hover {
    background-color: transparent;
    border-color: transparent;
    color: #4d4d4f;
  }

  :focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
    border-color: transparent;
    color: #4d4d4f;
  }

  :focus:not(.focus-visible) {
    outline: 0;
    box-shadow: none;
  }
`;
*/
export const SpanClose = styled.span`
  color: #4d4d4f;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
`;

export const CustomIcon = styled.button`
  background-image: url("/images/general/close.svg");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  cursor: default;
  opacity: 1;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-left: 0px;
  margin-right: 15px;
  @media only screen and (max-width: 992px) {
    margin-right: 0px;
  }
`;

export const CustomPlusIcon = styled.button`
  background-image: url("/images/general/plus.svg");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  cursor: default;
  opacity: ${(props) => (props.theme.flagEnable ? 0.3 : 1)};
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-right: 5px;
`;

export const CustomMinusIcon = styled.button`
  /* background: url("/images/general/minus.svg"); */
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  cursor: default;
  opacity: ${(props) => (props.theme.flagEnable ? 1 : 0.3)};
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-right: 5px;
`;

export const ButtonImage = styled.img`
  width: 22px;
  height: 22px;
  margin-top: -2px;
  margin-left: -6px;
  //fill: red;
`;
export const CustomAddPlusIcon = styled.img`
  background-image: url("/images/general/plus.svg");
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  cursor: default;
  opacity: 1;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-right: 5px;
  border-radius: 50%;
`;

export const LabelCertificate = styled.div`
  color: #434573;
  font-size: 10px;
  font-weight: 700;
  margin-top: 0.25rem;
`;
export const TitleColRooms = styled.div`
  font-size: 15px;
  @media only screen and (max-width: 992px) {
    //display: none;
  }
`;
