import { Col, Container, Modal, ModalFooter } from "react-bootstrap";
import styled from "styled-components";

export const ModalHeader = styled(Modal.Header)`
  height: 60px;
  box-shadow: 1px 1px 4px 1px #ccc;
`;
export const ModalHeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

export const ModalTitle = styled(Modal.Title)`
  font-size: 14px;
  font-weight: 600;
  color: #686ea3;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-top: 3px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
  }
`;
export const ModalClose = styled.img`
  width: 1rem;
  height: 1rem;
  margin-top: 0.3rem;
`;

export const ModalBody = styled(Modal.Body)``;

export const ModalButtonClose = styled.button`
  border: none;
  color: #fff;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  width: 230px;
  height: 40px;
  background: #404377;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  /* text-transform: capitalize; */
  @media only screen and (max-width: 992px) {
    width: 150px;
  }
`;

export const CustomModalFooter = styled(ModalFooter)`
  align-items: center;
  justify-content: center;
`;
export const ColAddRoom = styled(Col)`
  @media only screen and (min-width: 992.1px) {
    display: none;
  }
`;
export const ColBtnClose = styled(Col)`
  @media only screen and (max-width: 992px) {
    padding-left: 3rem;
  }
`;
