import { Overlay } from "react-bootstrap";
import { GuestConfigurationBody } from "./Body";
import { MainContainer, PopoverCustom } from "./styles";
import { useGlobalContext } from "@hooks/useGlobalContext";

type GestConfigurationOverlayPropsType = {
  isMobile: boolean;
  target: any;
  show: boolean;
  setShow: any;
  setGuestConfiguration: (config: any) => void;
  removeGuestConfiguration: (id: number) => void;
  setPersonTotal: (config: any, type: string) => void;
};

export const GuestConfigurationOverlay = ({
  isMobile,
  show,
  target,
  setShow,
  removeGuestConfiguration,
  setPersonTotal,
}: GestConfigurationOverlayPropsType) => {
  const { search, setSearch } = useGlobalContext();
  const handleUpdateGuestValue = (
    id: number,
    type: "adult" | "children" | "room",
    process: string
  ) => {
    const updatedData = search?.paxConfig?.map((data, index) => {
      if (index == id) {
        switch (type) {
          case "adult":
            data.adult =
              process == "plus"
                ? data.adult + 1
                : data.adult == 1
                ? 1
                : data.adult - 1;
            break;
          case "children":
            data.children =
              process == "plus"
                ? data.children >= 4
                  ? 4
                  : data.children + 1
                : data.children <= 0
                ? 0
                : data.children - 1;
            if (process == "plus") {
              data.child.push({ age: "1" });
            } else {
              data.child.pop();
            }
            break;
          case "room":
            data.room =
              process == "plus"
                ? data.room + 1
                : data.room >= 1
                ? 1
                : data.room - 1;
            break;
        }
      }
      return data;
    });

    setSearch({ ...search, paxConfig: updatedData });
    setPersonTotal(search.paxConfig, "adult");
    setPersonTotal(search.paxConfig, "children");
  };

  return (
    <MainContainer fluid>
      <Overlay
        show={show}
        target={target}
        placement="bottom-start"
        containerPadding={20}
        onHide={() => setShow(false)}
        rootClose={true}
      >
        <PopoverCustom
          id="popover-contained-gest"
          className="guest-config-custom-popover"
        >
          <GuestConfigurationBody
            isMobile={isMobile}
            setShow={setShow}
            removeGuestConfiguration={removeGuestConfiguration}
            updateGuestValue={handleUpdateGuestValue}
            setPersonTotal={setPersonTotal}
          />
        </PopoverCustom>
      </Overlay>
    </MainContainer>
  );
};
