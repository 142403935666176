import useIsMountedRef from "@hooks/useIsMountedRef";
import { getRecommendedHotels } from "@lib/api/getRecommendedHotels";
import { useCallback, useEffect, useState } from "react";
import { DropDownMenu } from "./DropDownMenu";
import { ColMain } from "./styles";
import { useRouter } from "next/router";
import { getDateRange } from "@helpers/helper";
import moment from "moment";
import { useGlobalContext } from "@hooks/useGlobalContext";

type RecommendedProps = {
  show: boolean;
  currentSearch?: searchByRoomOccupancyType;
  isMobile: boolean;
};

export const Recommended: React.FC<RecommendedProps> = ({
  show = false,
  currentSearch,
  isMobile,
}) => {
  const router = useRouter();
  const isMountedRef = useIsMountedRef();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<SearchByRoomOccupancyResponseType | null>(
    null
  );
  const { search } = useGlobalContext();

  const getData = () => {
    const options: {
      value: any;
      label: string;
      price?: PriceType;
      no_of_nights?: number;
    }[] = [
      {
        value: null,
        label: currentSearch?.hotelName || "",
      },
    ];
    if (data) {
      const dataResult = data.result;
      const hotels = dataResult?.hotels;

      hotels?.map((hotel) => {
        options.push({
          value: hotel.vid,
          label: hotel.hotel_name,
          price: hotel.price,
          no_of_nights: Number(hotel.no_of_nights || 1),
        });
      });
    }

    return options;
  };
  const getHotelsRecommendedData = useCallback(async () => {
    try {
      if (isMountedRef.current) {
        // console.log("getRecommendedHotels >>currentSearch", currentSearch);
        if (
          currentSearch &&
          currentSearch.latitude &&
          currentSearch.longitude
        ) {
          const response = await getRecommendedHotels(
            currentSearch.v_id,
            Number(currentSearch.latitude),
            Number(currentSearch.longitude),
            String(currentSearch.check_in_date),
            String(currentSearch.check_out_date),
            currentSearch.rooms,
            String(currentSearch.nationality),
            String(currentSearch.currency),
            String(currentSearch.language),
            String(currentSearch.search_text),
            Number(currentSearch.starRating)
          );

          setData(response);
          setLoading(false);
          console.log("getRecommendedHotels", response);
        }
      }
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMountedRef, currentSearch?.latitude, currentSearch?.longitude]);

  const handlerChangeHotel = (select: OptionsType) => {
    const dataResult = data?.result;
    const hotels = dataResult?.hotels;

    const hotel = hotels?.find((h) => h.vid == select.value);

    if (hotel) {
      router
        .push(
          {
            pathname: "/hotel-details",
            query: {
              v_id: hotel.vid,
              language: currentSearch?.language,
              search_id: hotel.search_id,
              unique_id: hotel.uid,
              id: "",
              destination_id: currentSearch?.search_text,
              search_type: currentSearch?.search_type,
              /*dateRange: getDateRange(
                moment(currentSearch?.check_in_date),
                moment(currentSearch?.check_out_date)
              ),*/ //"Wed, Sep 14 22 - Fri, Sep 16 22",
              dateRange: getDateRange(
                moment(search.dates.checkIn),
                moment(search.dates.checkOut)
              ),
              paxConfig: JSON.stringify(search.paxConfig),
              dest_type: currentSearch?.destType,
              Location: currentSearch?.location,
            },
          },
          undefined,
          { shallow: true }
        )
        .then(() => router.reload());
    }
  };
  useEffect(() => {
    getHotelsRecommendedData();
  }, [getHotelsRecommendedData]);

  if (!show || !currentSearch?.hotelName) return null;

  return (
    <ColMain xs={12} md={6} lg={3}>
      <DropDownMenu
        title={currentSearch?.hotelName || ""}
        options={getData()}
        value={{
          value: null,
          label: "",
        }}
        onChange={handlerChangeHotel}
        loading={loading}
        icon="/images/general/pages/HotelOn.svg"
        isMobile={isMobile}
      />
    </ColMain>
  );
};
