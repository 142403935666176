export const autoCompleteIcons = [
  { type: "1", url: "/images/autocomplete-icon/1.svg" },
  {
    type: "2",
    url: "/images/autocomplete-icon/CountryInvoice.svg",
    label: {
      en: "Country",
      de: "Land",
      sv: "Land",
      sw: "Land",
      es: "Pais",
      pt: "País",
      fr: "Pays",
      zh: "国家",
      ch: "国家",
      jp: "国",
      ja: "国",
      ru: "Страна",
    },
  },
  {
    type: "3",
    url: "/images/autocomplete-icon/RegionInvoice.svg",
    label: {
      en: "State",
      de: "Bundesland",
      sv: "län",
      sw: "län",
      es: "Estado",
      pt: "Estado",
      fr: "État",
      zh: "状态",
      ch: "状态",
      jp: "州",
      ja: "州",
      ru: "Состояние",
    },
  },
  {
    type: "4",
    url: "/images/autocomplete-icon/RegionInvoice.svg",
    label: {
      en: "Multi City",
      de: "Stadt & Region",
      sv: "Region",
      sw: "Region",
      es: "Multi ciudad",
      pt: "Multi-cidade",
      fr: "Multi_cité",
      zh: "多城市",
      ch: "多城市",
      jp: "多都市",
      ja: "多都市",
      ru: "Многогородской",
    },
  },
  {
    type: "5",
    url: "/images/autocomplete-icon/RegionInvoice.svg",
    label: {
      en: "Region",
      de: "Region",
      sv: "Region",
      sw: "Region",
      es: "Región",
      pt: "Região",
      ch: "地区",
      zh: "地区",
      jp: "領域",
      ja: "領域",
      ru: "Область",
    },
  },
  {
    type: "6",
    url: "/images/autocomplete-icon/CityInvoice.svg",
    label: {
      en: "City",
      de: "Stadt",
      sv: "Stad",
      sw: "Stad",
      es: "Ciudad",
      pt: "Cidade",
      fr: "Ville",
      zh: "城市",
      ch: "城市",
      jp: "市",
      ja: "市",
      ru: "Город",
    },
  },
  {
    type: "7",
    url: "/images/autocomplete-icon/CityInvoice.svg",
    label: {
      en: "Neighborhood",
      de: "Nachbarschaft",
      sv: "Grannskap",
      sw: "Grannskap",
      es: "Barrio",
      pt: "Vizinhança",
      ch: "邻里",
      zh: "邻里",
      jp: "近所",
      ja: "近所",
      ru: "окрестности",
    },
  },
  {
    type: "8",
    url: "/images/autocomplete-icon/AirportInvoice.svg",
    label: {
      en: "Airport",
      de: "Flughafen",
      sv: "Flygplats",
      sw: "Flygplats",
      es: "Aeropuerto",
      pt: "Aeroporto",
      fr: "Aéroport",
      zh: "飞机场",
      ch: "飞机场",
      jp: "空港",
      ja: "空港",
      ru: "Аэропорт",
    },
  },
  {
    type: "9",
    url: "/images/autocomplete-icon/LandmarkInvoice.svg",
    label: {
      en: "Landmark",
      de: "Umgebung",
      sv: "landmärke",
      sw: "landmärke",
      es: "Punto de referencia",
      pt: "Marco",
      fr: "Repère",
      zh: "地标",
      ch: "地标",
      jp: "ランドマーク",
      ja: "ランドマーク",
      ru: "Ориентир",
    },
  },
  {
    type: "10",
    url: "/images/autocomplete-icon/Metro_TrainInvoice.svg",
    label: {
      en: "Railway Station",
      de: "Bahnhof",
      sv: "Tågstation",
      sw: "Tågstation",
      es: "Estación de ferrocarril",
      pt: "Estação Ferroviária",
      fr: "Gare",
      zh: "火车站",
      ch: "火车站",
      jp: "鉄道駅",
      ja: "鉄道駅",
      ru: "Железнодорожная станция",
    },
  },
  {
    type: "11",
    url: "/images/autocomplete-icon/Metro_TrainInvoice.svg",
    label: {
      en: "Metro Station",
      de: "U-Bahn Station",
      sv: "Tunnelbanestasjon",
      sw: "Tunnelbanestasjon",
      es: "Estacion de Metro",
      pt: "Estação de metrô",
      fr: "Station de métro",
      zh: "地铁站",
      ch: "地铁站",
      jp: "地下鉄駅",
      ja: "地下鉄駅",
      ru: "Станция метро",
    },
  },
  {
    type: "21",
    url: "/images/autocomplete-icon/HotelInvoice.svg ",
    label: {
      en: "Hotel",
      de: "Hotel",
      sv: "Hotel",
      sw: "Hotel",
      es: "Hotel",
      pt: "Hotel",
      fr: "Hôtel",
      zh: "酒店",
      ch: "酒店",
      jp: "ホテル",
      ja: "ホテル",
      ru: "Отель",
    },
  },
];

//   export const searchTypeFull ={
//   "Countries": {
//       "en": "Countries",
//       "de": "Länder",
//       "sv": "Länder",
//       "sw": "Länder",
//       "es": "Paises",
//       "pt": "Países",
//       "fr": "Des pays",
//       "zh": "各国",
//       "ch": "各国",
//       "jp": "国々",
//       "ja": "国々",
//       "ru": "Стран"
//   },
//   "region and Neighborhood": {
//       "en": "region and Neighborhood",
//       "de": "Region und Nachbarschaft",
//       "sv": "region och grannskap",
//       "sw": "region och grannskap",
//       "es": "región y barrio",
//       "pt": "região e vizinhança",
//       "ch": "地区和邻里",
//       "zh": "地区和邻里",
//       "jp": "地域と近隣",
//       "ja": "地域と近隣",
//       "ru": "регион и соседство"
//   },
//   "Center": {
//       "en": "Center",
//       "de": "Center",
//       "sv": "Centrum",
//       "sw": "Centrum",
//       "es": "Centro",
//       "pt": "Centro",
//       "ch": "中央",
//       "zh": "中央",
//       "jp": "中心",
//       "ja": "中心",
//       "ru": "центр"
//   }
// }
