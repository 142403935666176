import { useWhiteLabelLan } from "@hooks/useLayout";
import { useMain } from "@contexts/mainContext";
import {
  ModalCustom,
  DivContainer,
  DivBoldSignIn,
  SpanLabel,
  Header,
  DivImgCloseModal,
  ImgCloseModal,
  DivContainerSignIn,
  SignInBtn,
  ContainerLabelBtnSignin,
  DivContainerImgUser,
  ImgUser,
  LabelSignIn,
} from "./styles";

type B2BModalSignInProps = {
  show: boolean;
  handleCloseModal: (state: boolean) => void;
  isMobile: boolean;
};
export const B2BModalSignIn = ({
  show,
  handleCloseModal,
  isMobile,
}: B2BModalSignInProps) => {
  const { getLangLabel } = useWhiteLabelLan();
  const { functSetShowModalSignIn } = useMain();
  return (
    <ModalCustom
      show={show}
      onHide={() => {
        handleCloseModal(false);
      }}
      fullscreen={isMobile}
      theme={{ isMobile }}
    >
      <DivContainer>
        <Header>
          <DivImgCloseModal
            onClick={() => {
              handleCloseModal(false);
            }}
          >
            <ImgCloseModal />
          </DivImgCloseModal>
        </Header>
        <div>
          <SpanLabel>{`${getLangLabel("PLEASE")}`}</SpanLabel>
          <DivBoldSignIn>{`${getLangLabel("SIGN-IN")}`}</DivBoldSignIn>
          <SpanLabel>{`${getLangLabel(
            "TO CONTINUE WITH YOUR SEARCH"
          )}`}</SpanLabel>
        </div>
        <DivContainerSignIn>
          <SignInBtn
            onClick={() => {
              handleCloseModal(false);
              functSetShowModalSignIn(true);
            }}
          >
            <ContainerLabelBtnSignin>
              <DivContainerImgUser>
                <ImgUser />
              </DivContainerImgUser>
              <LabelSignIn>{`${getLangLabel("SIGN-IN")}`}</LabelSignIn>
            </ContainerLabelBtnSignin>
          </SignInBtn>
        </DivContainerSignIn>
      </DivContainer>
    </ModalCustom>
  );
};
