import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

export const DropdownMain = styled(Dropdown)`
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  & > .dropdown-toggle::after {
    display: none;
  }
  & > .dropdown-toggle::before {
    /*display: inline-block;*/
  }
  & > button {
    justify-content: center;
    align-items: center;
  }
  & > div {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    border-radius: 10px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-top: ${(props) => (props.theme.isMobilePage ? "0" : "0.1rem")};
  }

  & > div > .active {
    color: #fff;
    text-decoration: none;
    background-color: rgba(104, 110, 163, 0.4);
  }
`;

export const DropdownMainItem = styled(DropdownMain.Item)`
  height: 2.5rem;
  margin: auto;
  margin-top: 0.3rem;
  border-radius: 15px;
  padding: 0.1rem 1rem;
  line-height: 2.5rem;
  :hover {
    //background-color: #414278;
    //color: white;
  }
`;

export const DropdownMainMenu = styled(DropdownMain.Menu)`
  max-height: 15rem;
  overflow-y: auto;
  box-shadow: 0 0 4px 0 #aaa;
  min-width: ${(props) => (props.theme.isMobilePage ? "100%" : "")};
  width: ${(props) => (props.theme.isMobilePage ? "100%" : "")};
  max-width: ${(props) => (props.theme.isMobilePage ? "100%" : "")};
`;

export const DropdownMainToggle = styled(DropdownMain.Toggle)`
  font-size: 14px;
  font-weight: 600;
  color: #575757;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.3rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: 0 solid #aeb1d6;
  box-shadow: 0 0 4px 0 #424577;
  width: 100%;
  max-width: ${(props) => (props.theme.isMobilePage ? "100%" : "12rem")};
  border-radius: ${(props) => (props.theme.isMobilePage ? "0px" : "50px")};
  max-height: ${(props) => (props.theme.isMobilePage ? "5rem" : "3rem")};

  @media only screen and (min-width: 992) {
    max-width: inherit;
  }
`;
export const HotelIcon = styled.img`
  content: ${(props) => (props.theme.icon ? `url("${props.theme.icon}")` : "")};
  width: 1.3rem;
  margin-left: ${(props) => (props.theme.isMobilePage ? "3.5rem" : "0rem")};
  margin-right: ${(props) => (props.theme.isMobilePage ? "1rem" : "0.5rem")};
  margin-top: ${(props) => (props.theme.isMobilePage ? "0rem" : "0rem")};
`;
export const TextIconArrowDown = styled.img`
  content: ${(props) => (props.theme.icon ? `url("${props.theme.icon}")` : "")};
  width: 0.5rem;
  margin-left: ${(props) => (props.theme.isMobilePage ? "0.5rem" : "0rem")};
  margin-right: ${(props) => (props.theme.isMobilePage ? "1rem" : "-1rem")};
  margin-top: ${(props) => (props.theme.isMobilePage ? "0rem" : "0rem")};
`;

export const DDContentText = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin-right: -0.7rem;
`;
export const DDText = styled.p`
  max-width: ${(props) => (props.theme.isMobilePage ? "15rem" : "10rem")};
  max-height: ${(props) => (props.theme.isMobilePage ? "5rem" : "20rem")};
  margin-bottom: ${(props) => (props.theme.isMobilePage ? "0rem" : "0rem")};
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ArrowDownSpan = styled.span`
  display: inline-block;
`;

export const Discount = styled.span`
  display: inline-block;
  color: green;
  font-weight: 500;
`;

export const PerNight = styled.span`
  display: inline-block;
  color: darkorange;
  font-weight: 500;
`;
