import styled from "styled-components";
import { Button } from "react-bootstrap";

export const CustomLabel = styled.span`
  color: ${(props) => (props.theme.color ? props.theme.color : "##6b71a6")};
  font-size: 18px;
  font-weight: ${(props) => (props.theme.isMobile ? 600 : "")};
  margin: 2px;
  margin-left: 5px;
  margin-right: 10px;
  text-align: center;
  @media only screen and (max-width: 992px) {
    font-size: 14px;
  }
`;

export const AddRoomBtn = styled(Button)`
  background-color: transparent;
  border-color: transparent;
  color: #4d4d4f;
  width: max-content;
  font-size: 14px;
  padding: 0;
  margin-left: -12px;
  display: flex;
  align-items: center;
  :hover {
    background-color: transparent;
    border-color: transparent;
    color: #4d4d4f;
  }

  :focus {
    outline: none;
    box-shadow: none;
    background-color: transparent;
    border-color: transparent;
    color: #4d4d4f;
  }

  :focus:not(.focus-visible) {
    outline: 0;
    box-shadow: none;
  }
`;
export const SpanClose = styled.span`
  color: #4d4d4f;
  font-weight: bold;
  cursor: pointer;
  font-size: 18px;
`;

export const CustomIcon = styled.button`
  background-image: url("/images/general/close.svg");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  cursor: default;
  opacity: 1;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-left: 0px;
  margin-right: 15px;
  @media only screen and (max-width: 992px) {
    margin-right: 0px;
  }
`;

export const CustomPlusIcon = styled.button`
  background-image: url("/images/general/plus.svg");
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  cursor: default;
  opacity: ${(props) => (props.theme.flagEnable ? 0.3 : 1)};
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-right: 5px;
`;

export const CustomMinusIcon = styled.button`
  /* background: url("/images/general/minus.svg"); */
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  cursor: default;
  opacity: ${(props) => (props.theme.flagEnable ? 1 : 0.3)};
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-right: 5px;
`;

export const ButtonImage = styled.img`
  width: 22px;
  height: 22px;
  margin-top: -2px;
  margin-left: -6px;
  //fill: red;
`;
export const CustomAddPlusIcon = styled.img`
  background-image: url("/images/general/plus.svg");
  width: 21px;
  height: 21px;
  background-repeat: no-repeat;
  cursor: default;
  opacity: 1;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-right: 5px;
  border-radius: 50%;
`;

export const LabelCertificate = styled.div`
  color: #434573;
  font-size: 10px;
  font-weight: 700;
  margin-top: 0.25rem;
`;
export const TitleColRooms = styled.div`
  font-size: 15px;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`;
